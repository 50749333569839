import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { Alert } from '@material-ui/lab';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MetafileDownload from './MetafileDownload'

import { getFirestore, collection, query, where, getDocs } from "firebase/firestore/lite";
const db = getFirestore();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    metafiles: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        marginBottom: 15
    },
    title: {
        fontWeight: 600,
        paddingRight: 10,
    },
    goBack: {
        paddingTop: 10,
        paddingBottom: 10
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function IncalDetail({ data }) {
    const classes = useStyles();
    const [exactMatchMetafile, setExactMatchMetafile] = useState();
    const [greenZoneList, setGreenZoneList] = useState([]);
    const [closestInGreenZoneMetafile, setClosestInGreenZoneMetafile] = useState({});
    const [metafileFound, setMetafileFound] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function onLoad() {
            try {
                setExactMatchMetafile(undefined);
                setMetafileFound(false);
                // setShowComponents(true);
                // setWhatsApp("https://wa.me/17863159202?text=Hola%2C%20estoy%20interesado%20en%20el%20metafile%20para%20codigo%20ECM%20" + data.ECMCodeBase);
                const versionStrs = data.CalibrationVersion.split('.');
                const calVersionSlices = versionStrs.map(version => Number(version));
                console.log("calVersionSlices:", calVersionSlices);
                const calVersionSlice1 = calVersionSlices[0];
                const calVersionSlice2 = calVersionSlices[1];
                const calVersionSlice3 = calVersionSlices[2];
                const calVersionSlice4 = calVersionSlices[3];
                const q = query(collection(db, "Metafiles"), where("ProductId", "==", data.ProductId), where("CalVersionSlice1", "==", calVersionSlice1));
                const querySnapshot = await getDocs(q);
                const metafileList = querySnapshot.docs.map(doc => doc.data());

                // console.log(metafileList);
                const exactMatchMetafile = metafileList.find(metafile => metafile.CalVersionSlice1 === calVersionSlice1 && metafile.CalVersionSlice2 === calVersionSlice2 && metafile.CalVersionSlice3 === calVersionSlice3 && metafile.CalVersionSlice4 === calVersionSlice4);
                if (exactMatchMetafile) {
                    console.log("exactMatchMetafile is not empty");
                    setExactMatchMetafile(exactMatchMetafile);
                    setMetafileFound(true);
                    return;
                }

                // greenZone
                // 9.40.15._
                const greenZoneList = metafileList.filter(metafile => metafile.CalVersionSlice1 === calVersionSlice1 && metafile.CalVersionSlice2 === calVersionSlice2 && metafile.CalVersionSlice3 === calVersionSlice3)
                    .sort((a, b) => {
                        return a.calVersionSlice4 < b.calVersionSlice4;
                    });;
                // var goal = calVersionSlice4;
                console.log('greenZoneList: ', greenZoneList);

                if (Array.isArray(greenZoneList) && greenZoneList.length > 0) {
                    setMetafileFound(true);
                    processGreenZoneList(calVersionSlice4, greenZoneList);
                }

                const yellowZoneList = metafileList.filter(metafile => metafile.CalVersionSlice1 === calVersionSlice1 && metafile.CalVersionSlice2 === calVersionSlice2 && metafile.CalVersionSlice3 === calVersionSlice3 && metafile.CalVersionSlice4 === calVersionSlice4);
                const orangeZoneList = metafileList.filter(metafile => metafile.CalVersionSlice1 === calVersionSlice1 && metafile.CalVersionSlice2 === calVersionSlice2 && metafile.CalVersionSlice3 === calVersionSlice3 && metafile.CalVersionSlice4 === calVersionSlice4);
                const redZoneList = metafileList.filter(metafile => metafile.CalVersionSlice1 === calVersionSlice1 && metafile.CalVersionSlice2 === calVersionSlice2 && metafile.CalVersionSlice3 === calVersionSlice3 && metafile.CalVersionSlice4 === calVersionSlice4);
                // console.log("exactMatch ", exactMatch);
                // console.log("greenZoneList ", greenZoneList);
                // if (querySnapshot.empty) {
                //     setShowDetails(false);
                //     setShowNotFound(true);
                //     return;
                // }
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
        };
        setLoading(true);
        onLoad();
        setLoading(false);
    }, []);

    function processGreenZoneList(calVersionSlice4, greenZoneList) {
        console.log("greenZoneList is not empty: ", greenZoneList.length);
        // if (Array.isArray(metafileList) && metafileList.length) {
        //     console.log("metafileList is not empty: ", metafileList.length);
        //     setMetafileFound(true);
        // }
        const closestInGreenZoneMetafile = greenZoneList.reduce(function (prev, curr) {
            // console.log("curr: ", curr);
            return (Math.abs(curr.CalVersionSlice4 - calVersionSlice4) < Math.abs(prev.CalVersionSlice4 - calVersionSlice4) ? curr : prev);
        });
        const closestIndex = greenZoneList.indexOf(closestInGreenZoneMetafile);
        const greenZoneListReduced = [];

        if (greenZoneList[closestIndex - 1] && closestInGreenZoneMetafile.CalVersionSlice4 >= calVersionSlice4) {
            greenZoneListReduced.push(greenZoneList[closestIndex - 1]);
        }
        greenZoneListReduced.push(greenZoneList[closestIndex]);
        if (greenZoneList[closestIndex + 1] && closestInGreenZoneMetafile.CalVersionSlice4 <= calVersionSlice4) {
            greenZoneListReduced.push(greenZoneList[closestIndex + 1]);
        }
        // console.log('closest: ', closestInGreenZoneMetafile);
        // console.log('greenZoneListReduced: ', greenZoneListReduced);
        setGreenZoneList(greenZoneListReduced);
        setClosestInGreenZoneMetafile(closestInGreenZoneMetafile);
    }


    return (
        <>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h5" component="h2">
                    Detalles
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography className={classes.title} component="span">
                            Código ECM:
                        </Typography>
                        {data.ECMCodeBase}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.title} component="span">
                            Producto(ID):
                        </Typography>
                        {data.ProductId}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.title} component="span">
                            Calibración (Metafile):
                        </Typography>
                        {data.CalibrationVersion}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.title} component="span">
                            Nombre de módulo:
                        </Typography>
                        {data.ModuleName}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.title} component="span">
                            CPL:
                        </Typography>
                        {data.CPL}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.title} component="span">
                            # de parte:
                        </Typography>
                        {data.ECMPartNumber}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Button
                            target="_blank"
                            href={whatsApp}
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<WhatsAppIcon style={{ fill: "green" }} />}
                        >
                            Pedir el Metafile
                        </Button>
                    </Grid> */}
                </Grid>
            </Paper>
            {
                metafileFound ?
                    <Paper className={classes.paper}>
                        <Box marginTop={1} marginBottom={3} className={classes.metafiles}>
                            <Typography gutterBottom variant="h5" component="h2">
                                Descargar Metafile
                            </Typography>
                            {exactMatchMetafile ?
                                <>
                                    <Alert variant="filled" severity="success">Hemos encontrado el metafile exacto para la calibracion</Alert>
                                    <List className={classes.root}>
                                        <MetafileDownload key={exactMatchMetafile.CalibrationVersion} metafile={exactMatchMetafile} />
                                    </List>
                                </>
                                :
                                <>
                                    <Alert severity="success">Hemos encontrado los siguientes metafiles </Alert>
                                    <List className={classes.root}>
                                        {greenZoneList.map((metafile) => {
                                            return (
                                                <MetafileDownload key={metafile.CalibrationVersion} metafile={metafile} />
                                            );
                                        })}
                                    </List>
                                </>
                            }

                        </Box>
                    </Paper>
                    :
                    <Paper className={classes.paper}>
                        <Alert severity="error">
                            No hemos encontrado el metafile para la calibracion <strong>{data.ECMCodeBase}</strong>. Nuestra base de datos se actualiza todos los dias, intente nuevamente pronto.
                        </Alert>
                    </Paper>
            }
        </>
    )
}