import React from 'react';

import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import IncalSearch from "./IncalSearch";
import IncalDetail from "./IncalDetail";
import { AuthProvider } from "../contexts/AuthContext"


export default function App() {

  return (
    <div>
      <Router>
        <AuthProvider>
          <Switch>
            <PrivateRoute exact path="/" component={IncalSearch} />
            <PrivateRoute path="/incals/:incalId" component={IncalDetail} />
            {/* <PrivateRoute path="/update-profile" component={UpdateProfile} /> */}
            {/* <Route path="/signup" component={Signup} /> */}
            <Route path="/login" component={Login} />
            {/* <Route path="/forgot-password" component={ForgotPassword} /> */}
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}
