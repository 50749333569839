import React, { useEffect, useState } from 'react';

// import PropTypes from 'prop-types';
import './App.css';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// import { useHistory } from "react-router-dom"
import Layout from "./Layout";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IncalDetail from './IncalDetail'
import { getAuth } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, addDoc, Timestamp } from "firebase/firestore";
const db = getFirestore();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const useRowStyles = makeStyles({
    row: {
        cursor: "pointer",
    },
});

function IncalSearch() {
    const classes = useStyles();
    const [search, setSearch] = useState("");
    const [data, setData] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [showNotFound, setShowNotFound] = useState(false);

    const auth = getAuth();
    const user = auth.currentUser;

    const handleClick = async () => {
        setShowDetails(false);
        setShowNotFound(false);
        console.log('search click:' + search);
        const userSearchRef = await addDoc(collection(db, "UserSearch"), {
            UID: user.uid,
            UserEmail: user.email,
            SearchText: search,
            Date: Timestamp.fromDate(new Date())
        });

        const q = query(collection(db, "incals"), where("ECMCodeBase", "==", search));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            setShowDetails(false);
            setShowNotFound(true);
            return;
        }
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            setData(doc.data());
            setShowDetails(true);
        });
    };

    return (
        <Layout>
            <Box my={2}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Busqueda por Código ECM"
                            style={{ margin: 8 }}
                            placeholder="e.g: CL10130"
                            variant="outlined"
                            margin="dense"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={search}
                            onChange={(event) => setSearch(event.target.value.toUpperCase())}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary"
                            onClick={() => handleClick()}
                        >
                            Buscar
                        </Button>
                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
                {showDetails &&
                    <IncalDetail data={data}>
                    </IncalDetail>
                }
                {showNotFound &&
                    <Typography variant="h4" gutterBottom>
                        Incal Not Found
                    </Typography>
                }
            </Box>
        </Layout>
    );
}

// const Hits = ({ hits }) => (
//     <TableContainer component={Paper}>
//         <Table aria-label="simple table">
//             <TableHead>
//                 <TableRow>
//                     <TableCell>Código ECM</TableCell>
//                     <TableCell>Producto(ID)</TableCell>
//                     <TableCell>Calibración (Metafile)</TableCell>
//                     <TableCell>Nombre de módulo</TableCell>
//                     <TableCell>CPL</TableCell>
//                     <TableCell># de parte</TableCell>
//                 </TableRow>
//             </TableHead>
//             <TableBody>
//                 {hits.map(hit => (
//                     <Row key={hit.objectID} hit={hit} />
//                 ))}
//             </TableBody>
//         </Table>
//     </TableContainer>
// );


export default IncalSearch;
