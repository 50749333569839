import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from "../contexts/AuthContext"
import { useHistory } from "react-router-dom"

import { uiConfig, auth } from "../firebase"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore/lite";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const db = getFirestore();
const storage = getStorage();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
}));

export default function MetafileDownload({ metafile }) {
    const classes = useStyles();
    const [url, setUrl] = useState();

    const auth = getAuth();
    const user = auth.currentUser;

    useEffect(() => {
        async function onLoad() {
            try {
                getDownloadURL(ref(storage, "metafiles/" + metafile.FileUrl))
                    .then((url) => {
                        console.log("url: ", url);
                        setUrl(url);
                    })
                    .catch((error) => {
                        console.error(error);
                    });

            } catch (e) {
                console.log(e);
            }
        };
        onLoad();
    }, []);

    const downloadClick = async () => {
        console.log('downloadClick');
        const userRef = doc(db, 'users', user.uid);
        setDoc(userRef, { UID: user.uid, UserEmail: user.email, Metafile: metafile });
        const link = document.createElement("a");
        link.download = metafile.Filename;
        link.setAttribute(
            'download',
            metafile.Filename,
        );
        link.href = url;
        link.click();
    };

    return (
        <ListItem key={metafile.CalibrationVersion}
            selected={true}
            role={undefined} dense button>
            <ListItemText id={metafile.CalibrationVersion}>
                <Typography variant="h5" component="h2">
                    {metafile.CalibrationVersion}
                </Typography>
            </ListItemText>
            <ListItemIcon>
                <IconButton onClick={() => downloadClick()}>
                    <CloudDownloadIcon />
                </IconButton>
            </ListItemIcon>
        </ListItem>
    );
}