import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from "../contexts/AuthContext"
import { useHistory } from "react-router-dom"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { uiConfig, auth } from "../firebase"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
}));

export default function Login() {

    return (
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    );

    // const classes = useStyles();

    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");

    // const { login } = useAuth()
    // const [error, setError] = useState("")
    // const [loading, setLoading] = useState(false)
    // const history = useHistory()

    // async function handleSubmit(e) {
    //     e.preventDefault()

    //     try {
    //         setError("")
    //         setLoading(true)
    //         console.log("email: " + email);
    //         await login(email, password)
    //         history.push("/")
    //     } catch (e) {
    //         console.error(e);
    //         setError("Usuario o contraseña incorrecto.")
    //     }

    //     setLoading(false)
    // }

    // return (
    //     <div className={classes.root}>
    //         <Paper className={classes.paper}>
    //             <Grid
    //                 container
    //                 direction="column"
    //                 justifyContent="center"
    //                 alignItems="center"
    //             >
    //                 <form onSubmit={handleSubmit}>
    //                     <Grid item xs>
    //                         <h2 className="text-center mb-4">Iniciar Sesión</h2>
    //                     </Grid>
    //                     <Grid item xs>
    //                         {error && <Alert severity="error">{error}</Alert>}
    //                     </Grid>
    //                     <Grid item xs>
    //                         <TextField
    //                             id="email"
    //                             label="Email"
    //                             value={email}
    //                             onChange={(e) => setEmail(e.target.value)}
    //                         />
    //                     </Grid>
    //                     <Grid item xs>
    //                         <TextField
    //                             id="password"
    //                             label="Contraseña"
    //                             value={password}
    //                             type="password"
    //                             onChange={(e) => setPassword(e.target.value)}
    //                         />
    //                     </Grid>
    //                     <Grid item xs>
    //                         <Button
    //                             disabled={loading}
    //                             variant="contained"
    //                             color="primary"
    //                             type="submit"
    //                             style={{
    //                                 marginTop: '20px'
    //                             }}
    //                         >
    //                             Entrar
    //                         </Button>
    //                     </Grid>
    //                 </form>
    //             </Grid>
    //         </Paper>
    //     </div>
    // )
}